import React from 'react';
import { graphql } from 'gatsby';
import '../styles/app.scss';
import CoverImageTitle from '../components/coverImageTitle';
import ParentsLogo from '../components/parentsLogoGrid';
import CoverImage from '../components/coverImage';
import LogosGrid from '../components/logosGrid';
import PartnerSection from '../components/parternsSection';

const ReferencesTemplate = (props) => {
  const {
    data: {
      wordpressPage: {
        acf: {
          title,
          background_image: {
            localFile: {
              childImageSharp: {
                fluid: background_image,
              },
            },
          },
          partner_section: {
            partner_title,
            partner_subtitle,
            partner_quote,
            partner_description,
            bottom_text,
            partner_logo: {
              localFile: {
                childImageSharp: {
                  fixed: partner_logo,
                },
              },
            },
          },
          grid_2x_with_logos_container: {
            section_title: title_section_logos,
            section_subtitle: subtitle_logos,
            logos: logos_grid,
            button_label: button_label_grid2x,
          },
          first_main_image: {
            localFile: {
              childImageSharp: {
                fluid: first_main_image,
              },
            },
          },
          grid_5x_with_logos_container: {
            section_title: title_logos_container,
            section_subtitle: subtitle_logos_container,
            logos,
          },
        },
      },
    },
    pathContext,
  } = props;
 
  return (
    <div className="referenze-page">

      <div>
        <CoverImageTitle title={title} fluid={background_image} />
        <PartnerSection
          partner_title={partner_title}
          partner_subtitle={partner_subtitle}
          partner_quote={partner_quote}
          partner_description={partner_description}
          bottom_text={bottom_text}
          partner_logo={partner_logo}
        />
        <ParentsLogo 
          subtitle_logo_grid={subtitle_logos}
          title_logo_grid={title_section_logos}
          imagesLogoGrid={logos_grid}
          lang={pathContext.lang}
          button_label={button_label_grid2x}
        />
        <CoverImage fluid={first_main_image} />
        <LogosGrid
          section_subtitle={subtitle_logos_container}
          section_title={title_logos_container}
          logos={logos}
        />
      </div>

    </div>
  );
};
export const query = graphql`
  query($lang: String) {
    wordpressPage(
      slug: { eq: "referenzen" }
      wpml_current_locale: { eq: $lang }
    ) {
      title
      template
      path
      yoast_head
      acf {
        title
        background_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 1100, quality: 100) {
                ...GatsbyImageSharpFluid,
              }
            }
          }
        }
      partner_section {
        partner_title
        partner_subtitle
        partner_quote
        partner_description
        bottom_text
        partner_logo {
          localFile {
            childImageSharp {
              fixed(width: 135, height: 172, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
      grid_2x_with_logos_container {
        section_title
        section_subtitle
        logos {
          description
          link
          title
          button_label
          image {
            localFile {
              childImageSharp {
                fluid(maxHeight: 800, quality: 100) {
                  ...GatsbyImageSharpFluid,
                }
              }
            }
          }
        }
      }
      first_main_image {
        localFile {
          childImageSharp {
            fluid(maxHeight: 800, quality: 100) {
              ...GatsbyImageSharpFluid,
            }
          }
        }
      }
      grid_5x_with_logos_container {
        section_title
        section_subtitle
        logos {
          link
          image {
            localFile {
              childImageSharp {
                fluid(maxHeight: 800, quality: 100) {
                  ...GatsbyImageSharpFluid,
                }
              }
            }
          }
        }
      }

     
     
      }
    }
    wordpressAcfOptions {
    options {
      kontakt {
        de_label
        en_label
      }
      produkte {
        de_label
        en_label
      }
      referenzen {
        de_label
        en_label
      }
      sprache {
        de_label
        en_label
      }
      copyright_text
      contact_icons_footer {
        email
        phone
      }
      bottom_footer_right {
        name
        english_name
        url
      }
      bottom_footer_left {
        name
        english_name
        url
      }
    }
  }
  }
`;
export default ReferencesTemplate;
