import React from 'react';
import Img from 'gatsby-image';

import ReactHtmlParser from 'react-html-parser';

function parternsSection({
  partner_title,
  partner_subtitle,
  partner_quote,
  partner_description,
  bottom_text,
  partner_logo,
}) { 
  return (
    <div className="partner-section">
      <h2 className="partner-title">{ReactHtmlParser(partner_title)}</h2>
      <h3 className="partner-subtitle">{ReactHtmlParser(partner_subtitle)}</h3>
      <div className="partner-info-warpper">
        <div className="logo-quote-warpper">
          {partner_logo ? <Img fixed={partner_logo} /> : null}
          <p className="partner-quote">{ReactHtmlParser(partner_quote)}</p>
        </div>
        <p className="partner-description">{ReactHtmlParser(partner_description)}</p>
        <span className="bottom-text">{ReactHtmlParser(bottom_text)}</span>
      </div>

    </div>

  );
}

export default parternsSection;
